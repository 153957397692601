import {
  VpnKey
} from "@material-ui/icons";

import Login from "views/Login";

export default function shared() {
  return [
    {
      path: "/login",
      name: "Login",
      icon: VpnKey,
      component: Login,
      shared: true
    }
  ];
}