import React from "react";
import { useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";

// @material-ui/core components
import { Card, CardHeader, CardContent, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { useTeamsState } from "contexts/Teams";

const useStyles = makeStyles((theme) => ({
  header: {
    color: theme.palette.primary.main
  }
}));

export default function Logbook(props) {
  const selectedTeam = useTeamsState().team;

  const classes = useStyles();

  const intl = useIntl();

  const history = useHistory();

  const params = useParams();
  if (isNaN(params.teamId)) history.goBack();

  return (
    <Card>
      <CardHeader title={intl.formatMessage({id: 'teams.team.logbook', defaultMessage: 'Logbook'})} classes={{title: classes.header}} />
      <CardContent>
        <Typography variant="h3">Upcoming</Typography>
      </CardContent>
    </Card>
  );
}