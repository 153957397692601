import React from "react";
import { useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";

// @material-ui/core components
import { Card, CardHeader, CardContent, Typography, Grid, Paper, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { Description } from "@material-ui/icons";

import { useTeamsState } from "contexts/Teams";

const useStyles = makeStyles((theme) => ({
  header: {
    color: theme.palette.primary.main
  },
  root: {
    flexGrow: 1,
    margin: "20px 0"
  },
  fileContainer: {
    padding: "20px 10px",
    textAlign: "center",
    margin: 10,
    position: "relative",
    minWidth: 100
  },
  thumbnail: {
    width: 60,
    height: 40,
    objectFit: "contain",
    marginBottom: 10
  },
  name: {
    userSelect: "none"
  },
  link: {
    color: theme.palette.text.secondary,
    "&:hover": {
      color: theme.palette.primary.main
    }
  },
}));

export default function Materials(props) {
  const selectedTeam = useTeamsState().team;

  const classes = useStyles();

  const intl = useIntl();

  const history = useHistory();

  const params = useParams();
  if (isNaN(params.teamId)) history.goBack();

  return (
    <Card>
      <CardHeader title={intl.formatMessage({id: 'teams.team.theory/materials', defaultMessage: 'Theory / Materials'})} classes={{title: classes.header}} />
      <CardContent>
        <Grid container spacing={2} direction="row">
          {selectedTeam.course.materials.map((file, index) => (
            <Grid key={index} item>
              <Paper className={classes.fileContainer}>
                <Link classes={{ root: classes.link }} href={file.file_uri} target="_blank" rel="noreferrer">
                  {file.file_thumb ? <img className={classes.thumbnail} src={file.file_thumb} alt="" /> : <Description className={classes.thumbnail} />}
                  <Typography className={classes.name} title={file.description}>{file.title}</Typography>
                </Link>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
}