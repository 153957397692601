import React from "react";
import {Route, Switch, useLocation} from "react-router-dom";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
// @material-ui/icons components
import Search from "@material-ui/icons/Search";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import NavbarDropdown from "components/Dropdowns/NavbarDropdown.js";

import getRoutesDef from "routes/teams";

import componentStyles from "assets/theme/layouts/admin.js";
import {useIntl} from "react-intl";
import {authUser} from "contexts/Auth";
import { useTeamsState, useTeamsDispatch, unsetTeam } from "contexts/Teams";

const useStyles = makeStyles(componentStyles);

const Teams = ({history, match}) => {
  const intl = useIntl();
  const classes = useStyles();
  const location = useLocation();
  let routes = getRoutesDef(intl);
  const brandText = process.env.REACT_APP_NAME;

  const dispatch = useTeamsDispatch();
  const teamsState = useTeamsState();

  if (teamsState.team.course && !teamsState.team.course.has_focus_points) routes = routes.filter(r => r.path.includes('/focus-points'));
  if (teamsState.team.course && !teamsState.team.course.has_implementation_activities) routes = routes.filter(r => r.path.includes('/activities'));
  if (teamsState.team.course && !teamsState.team.course.has_video_and_analysis) routes = routes.filter(r => r.path.includes('/video'));

  React.useEffect(() => {
    if (!(location.pathname.search(/^\/teams\/[0-9]/) >= 0)) {
      unsetTeam(dispatch);
    }
  }, [location]);

  React.useEffect(() => {
    return () => {
      unsetTeam(dispatch);
    };
  }, []);

  const getBreadcrumb = (routes, prefix = "", path = []) => {
    routes.forEach(r => {
      if (location.pathname.search(
          // Transform a path like /admin/products/:id/values/:id to something able to regexp:
          // like /admin/products/[0-9]+/values/[0-9]+
          String('^' + prefix + r.path + '$').replaceAll(new RegExp('/:[a-z_\-]+', 'gi'), '/[0-9]+')
      ) === 0) {
        path.push({...r, href: prefix + r.path});
      }
      if (r.views) {
        path = getBreadcrumb(r.views, prefix + r.path, path);
      }
    });
    return path;
  };
  let breadcrumb = getBreadcrumb(routes);
  if (teamsState.team && teamsState.team.course && teamsState.team.course.title && breadcrumb.length === 1) breadcrumb = [{...breadcrumb[0], href: '', name: teamsState.team.course.title + ' / ' + teamsState.team.title }, ...breadcrumb];

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    // mainContent.current.scrollTop = 0;
    document.title = brandText + " - " + (breadcrumb.length ? breadcrumb[breadcrumb.length-1].name : 'Unknown');
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.roles && !authUser().hasRole(prop.roles)) {
        return null;
      }
      let rs = [];
      if (prop.views) {
        rs = getRoutes(prop.views, prop.path);
      }
      rs.push(<Route
          path={prop.path}
          component={prop.component}
          key={key}
          exact={prop.exact}
      />);
      return rs;
    });
  };

  return (
    <>
      <Sidebar
        routes={routes}
        logo={{
          innerLink: "/teams",
          imgSrc: (authUser().site && authUser().site.logo_uri)
            ? authUser().site.logo_uri
            : require("../assets/img/brand/logo.png").default,
          imgAlt: brandText,
        }}
        dropdown={<NavbarDropdown />}
        input={
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="outlined-adornment-search-responsive">
              Search
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-search-responsive"
              type="text"
              endAdornment={
                <InputAdornment position="end">
                  <Box
                    component={Search}
                    width="1.25rem!important"
                    height="1.25rem!important"
                  />
                </InputAdornment>
              }
              labelWidth={70}
            />
          </FormControl>
        }
      />
      <Box position="relative" className={classes.mainContent}>
        <AdminNavbar breadcrumb={breadcrumb} history={history} match={match}/>
        <Container
          maxWidth={false}
          component={Box}
          classes={{ root: classes.containerRoot }}
        >
          <Container classes={{ root: classes.mainRoot }}>
            <Switch>
              {getRoutes(routes)}
            </Switch>
          </Container>
          <AdminFooter />
        </Container>
      </Box>
    </>
  );
};

export default Teams;