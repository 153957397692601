import React, { useState } from "react";
import { Box, Typography, Button, Dialog, DialogContent, DialogTitle, TextField, DialogActions } from "@material-ui/core";

const CreateNoteDialog = ({ show, subtitle, onClose, onSave }) => {
  const [note, setNote] = useState("");
  const [hours, setHours] = useState(1);

  const changeHoursHandler = (e) => {
    const value = +e.target.value;
    if (value < 0.5 || value > 9.5) return;
    setHours(value);
  };

  return (
    <Dialog open={show} onClose={onClose} maxWidth="md">
      <DialogTitle disableTypography>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h2" color="primary">Create note</Typography>
          {subtitle && <Typography>{subtitle}</Typography>}
        </Box>
      </DialogTitle>
      <DialogContent>
        <TextField
          label="Note"
          InputLabelProps={{
            shrink: true
          }}
          variant="outlined"
          multiline
          fullWidth
          style={{marginBottom: 40}}
          value={note}
          onChange={e => setNote(e.target.value)}
        />
        <TextField
          label="Hours"
          InputLabelProps={{
            shrink: true
          }}
          variant="outlined"
          type="number"
          inputProps={{ step: "0.5" }}
          value={hours}
          onChange={changeHoursHandler}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Button variant="contained" color="primary" onClick={() => onSave(note, hours)}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateNoteDialog;