import React, { useState } from "react";
import { Card, CardHeader, CardContent, Typography, Box, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Delete } from "@material-ui/icons";
import moment from "moment";
import { useIntl } from "react-intl";
import hexToRgb from "assets/theme/hex-to-rgb.js";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "1rem 1.25rem"
  },
  header: {
    fontSize: 16,
    margin: 0
  },
  content: {
    position: "relative"
  },
  note: {
    marginBottom: 40,
    width: "90%",
    marginLeft: "auto"
  },
  trainer: {
    backgroundColor: "rgba("+hexToRgb(theme.palette.secondary.light)+", 0.5)",
    marginLeft: "unset",
  },
  action: {
    "&:hover": {
      color: "#000"
    }
  },
}));

const Note = ({ trainer, note, user, onDelete }) => {
  const classes = useStyles();
  const intl = useIntl();

  const [showDelete, setShowDelete] = useState(false);

  return (
    <Card className={trainer ? classes.note + ' ' + classes.trainer : classes.note}>
      <CardHeader classes={{root: classes.root, title: classes.header}} title={trainer ? "Trainer: " + note.user.name : note.user.name} action={<Typography>{moment(note.created_at).format("D. MMMM YYYY HH:mm")}</Typography>} />
      <CardContent className={classes.content}>
        <Typography>{note.note}</Typography>
        {note.user && note.user.id && user && user.id && +note.user.id === user.id && (
          <Box position="absolute" right="10px" top="10px">
            <IconButton onClick={() => setShowDelete(true)} size="small" className={classes.action} aria-label="edit">
              <Delete />
            </IconButton>
          </Box>
        )}
      </CardContent>
      {showDelete && (
        <Dialog open onClose={() => setShowDelete(false)}>
          <DialogTitle disableTypography><Typography variant="h2">{intl.formatMessage({id:"common.dialog.delete.title", defaultMessage: "Delete note"})}</Typography></DialogTitle>
          <DialogContent style={{overflowY:"clip"}}>
            <DialogContentText>{intl.formatMessage({id: "common.dialog.delete.message"})}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowDelete(false)}>{intl.formatMessage({id:"common.button.back"})}</Button>
            <Button color="primary" onClick={() => {setShowDelete(false);onDelete(note.id)}}>{intl.formatMessage({id:"common.tooltip.delete"})}</Button>
          </DialogActions>
        </Dialog>
      )}
    </Card>
  );
};

export default Note;