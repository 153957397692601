import React from "react";
import { useIntl } from "react-intl";
import moment from "moment";
import { useHistory } from "react-router-dom";

// @material-ui/core components
import { Paper, Box, Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import componentStyles from "assets/theme/views/admin/dashboard.js";

import { Launch } from "@material-ui/icons";

import Logo from "assets/img/brand/logo.png";

const useStyles = makeStyles(componentStyles);

export default function Home(props) {
  const classes = useStyles();

  const intl = useIntl();

  const history = useHistory();

  const theme = useTheme();

  const columns = [
    {title: intl.formatMessage({id: "course_teams.list.column.title", defaultMessage: "Title"}), field: "title"},
    {
      title: intl.formatMessage({id: "course_teams.list.column.course", defaultMessage: "Course"}),
      field: "course.title"
    },
    {
      title: intl.formatMessage({id: "course_teams.list.column.course", defaultMessage: "Trainer"}),
      render: row => (row.course_team_trainers ? row.course_team_trainers.map(t => t.user.name).join(", ") : "")
    },
    {
      title: intl.formatMessage({id: "course_teams.list.column.start_at", defaultMessage: "Start"}),
      field: "start_at",
      type: "datetime",
      render: row => row.start_at ? moment(row.start_at).format(intl.formatMessage({id: "common.date.format"})) : ""
    },
    {
      title: intl.formatMessage({id: "course_teams.list.column.total_days", defaultMessage: "Total days"}),
      field: "course_team_days_count"
    },
    {
      title: intl.formatMessage({id: "course_teams.list.column.finish_at", defaultMessage: "End"}),
      field: "end_at",
      type: "datetime",
      render: row => row.finish_at ? moment(row.finish_at).format(intl.formatMessage({id: "common.date.format"})) : ""
    },
  ];

  const actions = [
    {
      tooltip: intl.formatMessage({id: "home.go_to_team", defaultMessage: "Go to team"}),
      icon: Launch,
      rowClick: true,
      onClick: row => {
        history.push("teams/"+row.id);
      }
    }
  ];

  const date = new Date().toISOString().split("T")[0];
  
  return (
    <Paper>
      <Box classes={{ root: classes.containerRoot }}>
        <Box textAlign="center" marginBottom={4}>
          <img width={100} src={Logo} alt="" />
          <Typography variant="h2" color="primary">{intl.formatMessage({id: "home.welcome"})}</Typography>
          <Typography variant="h5" color="primary">Dansk center for ICDP</Typography>
        </Box>
      </Box>
    </Paper>
  );
}