import React, { useState, useEffect, useCallback } from "react";
import { useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";

import {graphQLApi} from "services/GraphQLApi";
import {useAuthDispatch, authRoles, authUser} from "contexts/Auth";

// @material-ui/core components
import {
  Card,
  CardHeader,
  CardContent,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Snackbar,
  FormControlLabel,
  Switch,
  Select,
  MenuItem,
  InputLabel,
  FormControl
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { ExpandMore } from "@material-ui/icons";

import { useTeamsState } from "contexts/Teams";

import CreateNoteDialog from "components/Notes/CreateNoteDialog";
import Note from "components/Notes/Note";

const useStyles = makeStyles((theme) => ({
  header: {
    color: theme.palette.primary.main
  },
  heading: {
    marginRight: 10,
    fontWeight: 600
  },
  count: {
    fontWeight: 600,
    fontSize: 14,
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    padding: "0 4px",
    height: 20,
    borderRadius: "100%"
  },
  details: {
    display: "block"
  },
  selectContainer: {
    minWidth: 130
  },
  select: {
    marginTop: theme.spacing(2)
  }
}));

export default function CourseProgram(props) {
  const [loading, setLoading] = useState(false);
  const [showCreateNote, setShowCreateNote] = useState(false);
  const [selectedStarMoment, setSelectedStarMoment] = useState({});
  const [starMoments, setStarMoments] = useState([]);
  const [userNotes, setUserNotes] = useState([]);
  const [allowTrainer, setAllowTrainer] = useState(true);
  const [selectedParticipantId, setSelectedParticipantId] = useState('');
  const [participants, setParticipants] = useState([]);

  /**
   * Notification
   */
  const [notification, setNotification] = React.useState({
    severity: 'info',
    show: false,
    message: '',
  });
  const notify = (message, color = 'info') => {
    setNotification({severity: color, message: message, show: true});
  }
  const closeNotification = () => setNotification({...notification, show: false});

  const roles = authRoles();
  let isTrainer = false;
  if (roles.length) isTrainer = (roles.findIndex(r => r.priority <= 75) >= 0);
  const user = authUser();

  const selectedTeam = useTeamsState().team;

  const classes = useStyles();

  const intl = useIntl();

  const history = useHistory();

  const params = useParams();
  if (isNaN(params.teamId)) history.goBack();

  const client = new graphQLApi(useAuthDispatch(), props.history);
  const stableClient = useCallback(client, []);
  useEffect(() => {
    const teamId = selectedTeam.id;
    const userId = user.id;
    const courseId = selectedTeam.course.id;
    setLoading(true);
    const trainerQuery = 'courseTeams' + (selectedTeam.id ? '(filter:{id:'+selectedTeam.id+'})' : '') +
      `{data{
        course_team_participants{user{id name}}
    }}`;
    stableClient.query('{courseStarMoments' + (courseId ? '(filter:{course_id:'+courseId+'})' : '') +
    `{data{
      id
    }}` +
    'userNotes' + (isTrainer ? '(filter:{course_team_id:'+teamId+'})' : userId && courseId ? '(filter:{user_id:'+userId+' course_id:'+courseId+'})' : '') +
    `{data{
      id
      user{id name roles{priority}}
      notable_id
      notable_type
      note
      hours
      created_at
    }}` +
    `${isTrainer ? trainerQuery : ''}` +
    '}').then(result => {
      setLoading(false);
      // console.log(result);
      if (result && result.hasOwnProperty('courseStarMoments')) {
        const courseStarMoments = result.courseStarMoments.data;
        setStarMoments(courseStarMoments);
      }
      if (result && result.hasOwnProperty('userNotes')) {
        const userNotes = result.userNotes.data;
        setUserNotes(userNotes);
      }
      if (result && result.hasOwnProperty('courseTeams')) {
        if (result.courseTeams.data.length !== 1) return;
        const participants = result.courseTeams.data[0].course_team_participants;
        setParticipants(participants);
      }
    });
  }, [selectedTeam]);

  const showCreateNoteHandler = (starMoment) => {
    setSelectedStarMoment(starMoment);
    setShowCreateNote(true);
  };

  const createNoteHandler = (note, hours) => {
    setShowCreateNote(false);
    const variables = {
      course_id: "ID",
      notable_type: "String",
      notable_id: "Int",
      note: "String",
      hours: "Float"
    };
    const data = {
      course_id: selectedTeam.course.id,
      notable_type: "courseStarMoment",
      notable_id: selectedStarMoment.id,
      note: note,
      hours: hours
    };
    if (isTrainer) variables.user_id = "ID";
    if (isTrainer) data.user_id = selectedParticipantId;
    stableClient.mutation('userNote', variables, data, 'id user{id name roles{priority}} created_at').then(result => {
      if (result && result.response) {
        const id = result.response.id;
        const userInfo = result.response.user;
        const created_at = result.response.created_at;
        notify(intl.formatMessage({id: "star_moment.edit.saved", defaultMessage: "Star moment was updated"}), "succes");
        setUserNotes([...userNotes, { id, user: userInfo, note, hours, notable_type: "App\\Model\\CourseStarMoment", notable_id: +selectedStarMoment.id, created_at }]);
      }
    });
  };

  const switchChangeHandler = (e) => {
    setAllowTrainer(e.target.checked);
  };

  const selectParticipantHandler = (e) => {
    setSelectedParticipantId(e.target.value);
  };

  const deleteNoteHandler = (id) => {
    stableClient.mutate(`($id:ID!) {userNoteDelete(
      id:$id
    )}`, {
      id
    }).then(r => {
      if (!r) return console.log('Could not delete note');
      setUserNotes(userNotes.filter(n => n.id !== id));
    });
  };

  let notesToShow = userNotes;
  if (isTrainer && selectedParticipantId === '') notesToShow = [];
  // if (isTrainer) {
  //   notesToShow = userNotes.filter();
  // }

  return (
    <Card>
      <CardHeader
        title={intl.formatMessage({id: 'teams.team.star_moments', defaultMessage: 'Star moments'})}
        classes={{title: classes.header}}
        action={isTrainer ? (
          <FormControl className={classes.selectContainer}>
            <InputLabel shrink id="selectParticipantLabel">Select participant</InputLabel>
            <Select
              labelId="selectParticipantLabel"
              id="selectParticipant"
              value={selectedParticipantId}
              onChange={selectParticipantHandler}
              displayEmpty
              className={classes.select}
            >
              {participants.map(p => <MenuItem key={p.user.id} value={p.user.id}>{p.user.name}</MenuItem>)}
            </Select>
          </FormControl>
        ) : (
          <FormControlLabel
            control={
              <Switch
                checked={allowTrainer}
                onChange={switchChangeHandler}
                name="allowTrainer"
                color="primary"
              />
            }
            label="Allow the trainer to see and comment"
          />
        )}
      />
      <CardContent>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          message={notification.message}
          style={{backgroundColor: notification.severity}}
          open={notification.show}
          onClose={closeNotification}
          autoHideDuration={6000}
        />
        {starMoments.map((starMoment, index) => {
            const filteredNotes = notesToShow.filter(n => n.notable_id === +starMoment.id && (isTrainer ? (n.user.id === selectedParticipantId || +n.user.id === user.id) : true));
          return (
            <Accordion key={index}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls={starMoment.id+"-content"}
                id={starMoment.id+"-header"}
              >
                <Typography className={classes.heading}>Star moment {index+1}&nbsp;</Typography>
                <Typography className={classes.count}>{filteredNotes.length}</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <Box>
                  {filteredNotes.map((note, index2) => {
                    const trainer = note.user.roles.findIndex(r => r.priority <= 75) >= 0;
                    return (
                      <Note key={index2} trainer={trainer} note={note} user={user} onDelete={deleteNoteHandler} />
                    );
                  })}
                  <Box textAlign="right">
                    <Button variant="contained" color="primary" onClick={() => showCreateNoteHandler(starMoment)}>Create note</Button>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </CardContent>
      <CreateNoteDialog
        show={showCreateNote}
        subtitle={`Note for star moment ${starMoments.findIndex(sm => sm.id === selectedStarMoment.id) + 1} on course ${selectedTeam.course.title}`}
        onClose={() => setShowCreateNote(false)}
        onSave={createNoteHandler}
      />
    </Card>
  );
}