import React, { useEffect, useCallback, useReducer, createContext, useContext } from "react";
import { useHistory } from "react-router-dom";

import {graphQLApi} from "services/GraphQLApi";
import {useAuthDispatch} from "contexts/Auth";

const TeamsStateContext = createContext();
const TeamsDispatchContext = createContext();

const ACTION_SET_TEAM = "ACTION_SET_TEAM";
const ACTION_UNSET_TEAM = "ACTION_UNSET_TEAM";
const ACTION_SET_LOADING = "ACTION_SET_LOADING";
const ACTION_UNSET_LOADING = "ACTION_UNSET_LOADING";

function teamsReducer(state, action) {
  switch (action.type) {
    case ACTION_SET_TEAM:
      return { ...state, team: action.team };
    case ACTION_UNSET_TEAM:
      return { ...state, team: {} };
    case ACTION_SET_LOADING:
      return { ...state, loading: true };
    case ACTION_UNSET_LOADING:
      return { ...state, loading: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export function TeamsProvider({ children }) {
  var [state, dispatch] = useReducer(teamsReducer, {
    team: {
      id: null,
      course: {
        id: null,
        title: null,
        description: null,
        materials: []
      }
    },
    loading: false
  });

  const history = useHistory();

  const client = new graphQLApi(useAuthDispatch(), history);
  const stableClient = useCallback(client, []);

  useEffect(() => {
    if (!(window.location.pathname.search(/^\/teams\/[0-9]/) >= 0)) return;
    
    let id = window.location.pathname.replace('/teams/', '');
    if (id.includes('/')) id = id.split('/')[0];
    dispatch({ type: ACTION_SET_LOADING });
    stableClient.query('{courseTeams' + (id ? '(filter:{id:'+id+'})' : '') +
      `{data{
        id
        title
        course{
          id
          title
          description
          has_focus_points
          has_implementation_activities
          has_video_and_analysis
          materials{title description type file_uri}
        }
        course_team_trainers{user{name}}
        start_at
        finish_at
        course_team_days{
          id course_day{id sorting title}
          start_at
          finish_at
          location
          address
          city
        }
      }}` +
      '}').then(result => {
        dispatch({ type: ACTION_UNSET_LOADING });
        if (result && result.hasOwnProperty('courseTeams')) {
          const team = result.courseTeams.data[0];
          dispatch({ type: ACTION_SET_TEAM, team });
        }
    });
  }, []);

  return (
    <TeamsStateContext.Provider value={state}>
      <TeamsDispatchContext.Provider value={dispatch}>
        {children}
      </TeamsDispatchContext.Provider>
    </TeamsStateContext.Provider>
  );
}

export function useTeamsState() {
  var context = useContext(TeamsStateContext);
  if (context === undefined) {
    throw new Error("useAuthState must be used within a TeamsProvider");
  }
  return context;
}

export function useTeamsDispatch() {
  var context = useContext(TeamsDispatchContext);
  if (context === undefined) {
    throw new Error("useTeamsDispatch must be used within a TeamsProvider");
  }
  return context;
}

export function setTeam(dispatch, team) {
  dispatch({ type: ACTION_SET_TEAM, team });
}

export function unsetTeam(dispatch) {
  dispatch({ type: ACTION_UNSET_TEAM });
}