import {
  Home as HomeIcon,
  Apps,
  MenuBook,
  Star,
  CenterFocusStrong,
  Accessibility,
  Description,
  VideoLibrary,
  Comment,
  QuestionAnswer,
  PermMedia,
  Dashboard as DashboardIcon,
  EmojiObjects
} from "@material-ui/icons";

import Home from "views/Course/Home";
import Welcome from "views/Course/Team/Welcome";
import CourseProgram from "views/Course/Team/CourseProgram";
import Materials from "views/Course/Team/Materials";
import StarMoments from "views/Course/Team/StarMoments";
import FocusPoints from "views/Course/Team/FocusPoints";
import Activities from "views/Course/Team/Activities";
import Video from "views/Course/Team/Video";
import Logbook from "views/Course/Team/Logbook";
import Evaluation from "views/Course/Team/Evaluation";
import Dashboard from "views/Course/Team/Dashboard";
import Media from "views/Course/Team/Media";
import Concepts from "views/Course/Team/Concepts";

export default function routes(intl) {
  const prefix = '/teams';
  return [
    {
      path: "",
      exact: true,
      name: intl.formatMessage({id: "teams.home", defaultMessage: "Home"}),
      icon: HomeIcon,
      component: Home,
    },
    {
      path: "/:teamId",
      exact: true,
      hidden: true,
      name: intl.formatMessage({id: "teams.team.welcome", defaultMessage: "Welcome"}),
      icon: Apps,
      component: Welcome
    },
    {
      path: "/:teamId/program",
      hidden: true,
      name: intl.formatMessage({id: "teams.team.course_program", defaultMessage: "Course Program"}),
      icon: MenuBook,
      component: CourseProgram
    },
    {
      path: "/:teamId/materials",
      hidden: true,
      name: intl.formatMessage({id: "teams.team.theory/materials", defaultMessage: "Theory / Materials"}),
      icon: Description,
      component: Materials
    },
    {
      path: "/:teamId/star-moments",
      hidden: true,
      name: intl.formatMessage({id: "teams.team.star_moments", defaultMessage: "Star moments"}),
      icon: Star,
      component: StarMoments
    },
    {
      path: "/:teamId/focus-points",
      hidden: true,
      name: intl.formatMessage({id: "teams.team.focus_points", defaultMessage: "Focus points"}),
      icon: CenterFocusStrong,
      component: FocusPoints
    },
    {
      path: "/:teamId/activities",
      hidden: true,
      name: intl.formatMessage({id: "teams.team.implementation_activities", defaultMessage: "Implementation activities"}),
      icon: Accessibility,
      component: Activities
    },
    {
      path: "/:teamId/video",
      hidden: true,
      name: intl.formatMessage({id: "teams.team.video", defaultMessage: "Video"}),
      icon: VideoLibrary,
      component: Video
    },
    {
      path: "/:teamId/logbook",
      hidden: true,
      name: intl.formatMessage({id: "teams.team.logbook", defaultMessage: "Logbook"}),
      icon: Comment,
      component: Logbook
    },
    {
      path: "/:teamId/evaluation",
      hidden: true,
      name: intl.formatMessage({id: "teams.team.evaluation", defaultMessage: "Evaluation"}),
      icon: QuestionAnswer,
      component: Evaluation
    },
    {
      path: "/:teamId/dashboard",
      hidden: true,
      name: intl.formatMessage({id: "teams.team.dashboard", defaultMessage: "Dashboard"}),
      icon: DashboardIcon,
      component: Dashboard
    },
    {
      path: "/:teamId/media",
      hidden: true,
      name: intl.formatMessage({id: "teams.team.media", defaultMessage: "Media"}),
      icon: PermMedia,
      component: Media
    },
    {
      path: "/:teamId/concepts",
      hidden: true,
      name: intl.formatMessage({id: "teams.team.concepts", defaultMessage: "Concepts"}),
      icon: EmojiObjects,
      component: Concepts
    }
  ].map(r => ({...r, path: prefix + r.path}));
}