import {
  Assessment,
  AssignmentInd,
  Business,
  Description,
  Domain,
  FormatListNumbered,
  Home as HomeIcon,
  Language,
  MenuBook,
  People,
  PermMedia,
  Person,
  Settings
} from "@material-ui/icons";

// import Home from "views/Home";
import Home from "views/admin/Home/Home";
import UsersProfile from "views/admin/Users/UsersProfile";
import UserList from "views/admin/Users/UserList";
import UserEdit from "views/admin/Users/UserEdit";
import LanguageList from "views/admin/Settings/LanguageList";
import LanguageEdit from "views/admin/Settings/LanguageEdit";
import SiteList from "views/admin/MutliSite/SiteList";
import SiteEdit from "views/admin/MutliSite/SiteEdit";
import DomainEdit from "views/admin/MutliSite/DomainEdit";
import RoleList from "views/admin/Settings/RoleList";
import RoleEdit from "views/admin/Settings/RoleEdit";
import OrganisationList from "views/admin/Organisations/OrganisationList";
import OrganisationEdit from "views/admin/Organisations/OrganisationEdit";
import CourseLevelList from "views/admin/Levels/CourseLevelList";
import CourseLevelEdit from "views/admin/Levels/CourseLevelEdit";
import CourseList from "views/admin/Courses/CourseList";
import CourseTeamList from "views/admin/Teams/CourseTeamList";
import MaterialList from "views/admin/Materials/MaterialList";
import MaterialEdit from "views/admin/Materials/MaterialEdit";
import MediaFolderList from "views/admin/Media/MediaFolderList";
import MediaFolderEdit from "views/admin/Media/MediaFolderEdit";
import ConceptList from "views/admin/Concepts/ConceptList";
import ConceptEdit from "views/admin/Concepts/ConceptEdit";
import {CourseEdit} from "views/admin/Courses/CourseEdit";
import CourseTeamEdit from "views/admin/Teams/CourseTeamEdit";

export default function routes(intl) {
  const prefix = '/admin';
  return [
    {
      path: "",
      exact: true,
      name: "Home",
      icon: HomeIcon,
      component: Home,
    },
    {
      path: "/user-profile",
      name: "User Profile",
      icon: Person,
      component: UsersProfile,
      hidden: true,
    },
    {
      path: "/sites",
      name: intl.formatMessage({
        id: "menu.sites.list",
        defaultMessage: "Sites",
      }),
      icon: Domain,
      component: SiteList,
      roles: ["sites_manager"],
      views: [
        {
          path: "/create",
          name: intl.formatMessage({
            id: "menu.sites.create",
            defaultMessage: "Create Site",
          }),
          component: SiteEdit,
          hidden: true,
        },
        {
          path: "/:site_id/domains/create",
          name: intl.formatMessage({
            id: "menu.domains.create",
            defaultMessage: "Create Domain",
          }),
          component: DomainEdit,
          hidden: true,
        },
        {
          path: "/:site_id/domains/:id",
          name: intl.formatMessage({
            id: "menu.domains.edit",
            defaultMessage: "Edit Domain",
          }),
          component: DomainEdit,
          hidden: true,
        },
        {
          path: "/:id",
          name: intl.formatMessage({
            id: "menu.sites.edit",
            defaultMessage: "Edit Site",
          }),
          component: SiteEdit,
          hidden: true,
        },
      ],
    },
    {
      path: "/courses",
      name: intl.formatMessage({id: "menu.courses.list", defaultMessage: "Courses"}),
      icon: Assessment,
      component: CourseList,
      roles: ["sysdev", "admin", "superuser"],
      views: [
        {
          path: "/create",
          name: intl.formatMessage({id: "menu.courses.create", defaultMessage: "Create course"}),
          component: CourseEdit,
          hidden: true,
        },
        {
          path: "/:id",
          name: intl.formatMessage({id: "menu.courses.edit", defaultMessage: "Edit course"}),
          component: CourseEdit,
          hidden: true,
        },
      ]
    },
    {
      path: "/teams",
      name: intl.formatMessage({id: "menu.course_teams.list", defaultMessage: "Course Teams"}),
      icon: People,
      component: CourseTeamList,
      roles: ["sysdev", "admin", "superuser"],
      views: [
        {
          path: "/:id",
          name: intl.formatMessage({id: "menu.course_teams.edit", defaultMessage: "Edit Team"}),
          component: CourseTeamEdit,
          hidden: true,
        },
        {
          path: "/create",
          name: intl.formatMessage({id: "menu.course_teams.create", defaultMessage: "Create Team"}),
          component: CourseTeamEdit,
          hidden: true,
        },
      ]
    },
    {
      path: "/organisations",
      name: intl.formatMessage({id: "menu.organisations.list", defaultMessage: "Organisations"}),
      icon: Business,
      component: OrganisationList,
      roles: ["sysdev", "admin", "superuser"],
      views: [
        {
          path: "/create",
          name: intl.formatMessage({id: "menu.organisations.create", defaultMessage: "Create Organisation"}),
          component: OrganisationEdit,
          hidden: true,
        },
        {
          path: "/:id",
          name: intl.formatMessage({id: "menu.organisations.edit", defaultMessage: "Edit Organisation"}),
          component: OrganisationEdit,
          hidden: true,
        },
      ]
    },
    {
      path: "/materials",
      name: intl.formatMessage({id: "menu.materials.list", defaultMessage: "Materials"}),
      icon: Description,
      component: MaterialList,
      roles: ["sysdev", "admin", "superuser"],
      views: [
        {
          path: "/create",
          name: intl.formatMessage({id: "menu.materials.create", defaultMessage: "Create Material"}),
          component: MaterialEdit,
          hidden: true,
        },
        {
          path: "/:id",
          name: intl.formatMessage({id: "menu.materials.edit", defaultMessage: "Edit Material"}),
          component: MaterialEdit,
          hidden: true,
        },
      ]
    },
    {
      path: "/media",
      name: intl.formatMessage({id: "menu.media_folders.list", defaultMessage: "Media"}),
      icon: PermMedia,
      component: MediaFolderList,
      roles: ["sysdev", "admin", "superuser"],
      views: [
        {
          path: "/create",
          name: intl.formatMessage({id: "menu.media_folders.create", defaultMessage: "Create media folder"}),
          component: MediaFolderEdit,
          hidden: true,
        },
        {
          path: "/:id",
          name: intl.formatMessage({id: "menu.media_folders.edit", defaultMessage: "Edit media folder"}),
          component: MediaFolderEdit,
          hidden: true,
        },
      ]
    },
    {
      path: "/concepts",
      name: intl.formatMessage({id: "menu.concepts.list", defaultMessage: "Concepts"}),
      icon: MenuBook,
      component: ConceptList,
      roles: ["sysdev", "admin", "superuser"],
      views: [
        {
          path: "/create",
          name: intl.formatMessage({id: "menu.concepts.create", defaultMessage: "Create Concept"}),
          component: ConceptEdit,
          hidden: true,
        },
        {
          path: "/:id",
          name: intl.formatMessage({id: "menu.concepts.edit", defaultMessage: "Edit Concept"}),
          component: ConceptEdit,
          hidden: true,
        },
      ]
    },
    {
      path: "/settings",
      name: intl.formatMessage({
        id: "menu.settings",
        defaultMessage: "Settings",
      }),
      icon: Settings,
      roles: ["sysdev", "admin", "sites_manager", "superuser"],
      views: [
        {
          path: "/levels",
          name: intl.formatMessage({id: "menu.course_levels.list", defaultMessage: "Course Levels"}),
          icon: FormatListNumbered,
          component: CourseLevelList,
          roles: ["sysdev", "admin", "superuser"],
          views: [
            {
              path: "/create",
              name: intl.formatMessage({id: "menu.course_levels.create", defaultMessage: "Create Course Level"}),
              component: CourseLevelEdit,
              hidden: true,
            },
            {
              path: "/:id",
              name: intl.formatMessage({id: "menu.course_levels.edit", defaultMessage: "Edit Course Level"}),
              component: CourseLevelEdit,
              hidden: true,
            },
          ]
        },
        {
          path: "/languages",
          name: intl.formatMessage({id: "menu.languages.list", defaultMessage: "Languages"}),
          icon: Language,
          component: LanguageList,
          roles: ["sysdev", "admin"],
          views: [
            {
              path: "/create",
              name: intl.formatMessage({id: "menu.languages.create", defaultMessage: "Create Language"}),
              component: LanguageEdit,
              hidden: true,
            },
            {
              path: "/:id",
              name: intl.formatMessage({id: "menu.languages.edit", defaultMessage: "Edit Language"}),
              component: LanguageEdit,
              hidden: true,
            },
          ]
        },
        {
          path: "/roles",
          name: intl.formatMessage({id: "menu.roles.list", defaultMessage: "Roles"}),
          icon: AssignmentInd,
          component: RoleList,
          roles: ["sysdev", "admin"],
          views: [
            {
              path: "/create",
              name: intl.formatMessage({id: "menu.roles.create", defaultMessage: "Create Role"}),
              component: RoleEdit,
              hidden: true,
            },
            {
              path: "/:id",
              name: intl.formatMessage({id: "menu.roles.edit", defaultMessage: "Edit Role"}),
              component: RoleEdit,
              hidden: true,
            },
          ]
        },
        {
          path: "/users",
          name: intl.formatMessage({
            id: "menu.users.list",
            defaultMessage: "Users",
          }),
          icon: Person,
          component: UserList,
          roles: ["sysdev", "admin", "superuser"],
          views: [
            {
              path: "/create",
              name: intl.formatMessage({
                id: "menu.users.create",
                defaultMessage: "Create user",
              }),
              component: UserEdit,
              hidden: true
            },
            {
              path: "/:id",
              name: intl.formatMessage({
                id: "menu.users.edit",
                defaultMessage: "Edit user",
              }),
              component: UserEdit,
              hidden: true
            },
          ],
        },
      ]
    },
  ].map(r => ({...r, path: prefix + r.path}));
}