import React, { useState, useEffect, useCallback } from "react";
import { useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
// import moment from "moment";

import {graphQLApi} from "services/GraphQLApi";
import {useAuthDispatch, authRoles, authUser} from "contexts/Auth";

// @material-ui/core components
import { Card, CardHeader, CardContent, Box, Accordion, AccordionSummary, AccordionDetails, Typography, Button, Snackbar, Dialog, DialogContent, DialogActions, DialogTitle, TextField, FormControlLabel, Switch } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { ExpandMore } from "@material-ui/icons";

import { useTeamsState } from "contexts/Teams";

const useStyles = makeStyles((theme) => ({
  header: {
    color: theme.palette.primary.main
  },
  heading: {
    marginRight: 10,
    fontWeight: 600
  },
  count: {
    fontWeight: 600,
    fontSize: 14,
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    padding: "0 4px",
    height: 20,
    borderRadius: "100%"
  },
  details: {
    display: "block"
  },
  note: {
    backgroundColor: theme.palette.grey[300]
  }
}));

export default function FocusPoints(props) {
  const [loading, setLoading] = useState(false);
  const [showCreateFocusPoint, setShowCreateFocusPoint] = useState(false);
  const [selectedFocusPoint, setSelectedFocusPoint] = useState({});
  const [focusPoints, setFocusPoints] = useState([]);
  const [title, setTitle] = useState('');
  const [allowTrainer, setAllowTrainer] = useState(true);
  const [description, setDescription] = useState('');

  /**
   * Notification
   */
  const [notification, setNotification] = React.useState({
    severity: 'info',
    show: false,
    message: '',
  });
  const notify = (message, color = 'info') => {
    setNotification({severity: color, message: message, show: true});
  }
  const closeNotification = () => setNotification({...notification, show: false});

  const roles = authRoles();
  let isTrainer = false;
  if (roles.length) isTrainer = (roles.findIndex(r => r.priority <= 75) >= 0);
  const user = authUser();

  const selectedTeam = useTeamsState().team;

  const classes = useStyles();

  const intl = useIntl();

  const history = useHistory();

  const params = useParams();
  if (isNaN(params.teamId)) history.goBack();

  const client = new graphQLApi(useAuthDispatch(), props.history);
  const stableClient = useCallback(client, []);
  useEffect(() => {
    const id = selectedTeam.course.id;
    setLoading(true);
    stableClient.query('{userCourseFocusPoints' + (id ? '(filter:{course_id:'+id+'})' : '') +
    `{data{
      id
      title
      description
      user_notes{
        id
        user{name}
        note
        created_at
      }
    }}` +
    '}').then(result => {
      setLoading(false);
      if (result && result.hasOwnProperty('coursefocusPoints')) {
        const coursefocusPoints = result.coursefocusPoints.data;
        setFocusPoints(coursefocusPoints);
      }
    });
  }, [selectedTeam]);

  const showCreateFocusPointHandler = (starMoment) => {
    setSelectedFocusPoint(starMoment);
    setShowCreateFocusPoint(true);
  };

  const createFocusPointHandler = () => {
    setShowCreateFocusPoint(false);
    const variables = {
      course_id: "ID",
      title: "String",
      description: "String"
    };
    const data = {
      course_id: selectedTeam.course.id,
      title: title,
      description: description
    };
    stableClient.mutation('userCourseFocusPoint', variables, data, 'id').then(result => {
      if (result && result.response) {
        const id = result.response.id;
        // const userName = result.response.user.name;
        // const created_at = result.response.created_at;
        // notify(intl.formatMessage({id: "star_moment.edit.saved", defaultMessage: "Star moment was updated"}), "succes");
        // const newfocusPoints = [...focusPoints];
        // const index = focusPoints.findIndex(s => s.id === selectedFocusPoint.id);
        // newfocusPoints[index] = { ...newfocusPoints[index], user_notes: [...newfocusPoints[index].user_notes, { id, user: {name: userName}, note, created_at }] };
        setFocusPoints([...focusPoints, {}]);
      }
    });
  };

  return (
    <Card>
      <CardHeader
        title={intl.formatMessage({id: 'teams.team.focus_points', defaultMessage: 'Focus points'})}
        classes={{title: classes.header}}
        action={!isTrainer ? 
          <p>Trainer!</p> : 
          <Button onClick={() => setShowCreateFocusPoint(true)} variant="contained" color="primary">Create focuspoint</Button>
      }
      />
      <CardContent>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          message={notification.message}
          style={{backgroundColor: notification.severity}}
          open={notification.show}
          onClose={closeNotification}
          autoHideDuration={6000}
        />
        {focusPoints.map((starMoment, index) => (
          <Accordion key={index}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls={starMoment.id+"-content"}
              id={starMoment.id+"-header"}
            >
              <Typography className={classes.heading}>Star moment {index+1}&nbsp;</Typography>
              <Typography className={classes.count}>{starMoment.user_notes.length}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <Box>
                {starMoment.user_notes.map((note, index2) => (
                  <Card key={index2} className={classes.note}>
                    <CardHeader title={note.user.name} />
                    <CardContent>
                      <Typography>{note.note}</Typography>
                    </CardContent>
                  </Card>
                ))}
                <Box textAlign="right">
                  <Button variant="contained" color="primary" onClick={() => showCreateFocusPointHandler(starMoment)}>Create note</Button>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
        <Dialog open={showCreateFocusPoint} onClose={() => setShowCreateFocusPoint(false)}>
          <DialogTitle disableTypography><Typography variant="h3" color="primary">Create focus point</Typography></DialogTitle>
          <DialogContent>
            <Box display="flex" justifyContent="space-evenly" alignItems="center" style={{marginBottom: 40}}>
              <TextField
                label="Title"
                InputLabelProps={{
                  shrink: true
                }}
                variant="outlined"
                value={title}
                onChange={e => setTitle(e.target.value)}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={allowTrainer}
                    onChange={(e) => setAllowTrainer(e.target.checked)}
                    name="allowTrainer"
                    color="primary"
                  />
                }
                label="Allow the trainer to see and comment"
              />
            </Box>
            <TextField
              label="Description"
              InputLabelProps={{
                shrink: true
              }}
              variant="outlined"
              fullWidth
              value={description}
              onChange={setDescription}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowCreateFocusPoint(false)}>Close</Button>
            <Button variant="contained" color="primary" onClick={createFocusPointHandler}>Save</Button>
          </DialogActions>
        </Dialog>
      </CardContent>
    </Card>
  );
}