import React, { useState, useEffect, useCallback } from "react";
import { useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";

import {graphQLApi} from "services/GraphQLApi";
import {useAuthDispatch, authRoles} from "contexts/Auth";

// @material-ui/core components
import { Card, CardHeader, CardContent, Box, Accordion, AccordionSummary, AccordionDetails, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Snackbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { ExpandMore } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  header: {
    color: theme.palette.primary.main
  },
  heading: {
    marginRight: 10,
    fontWeight: 600
  },
  details: {
    display: "block"
  }
}));

export default function CourseProgram(props) {
  const [loading, setLoading] = useState(false);
  const [teamDays, setTeamDays] = useState([]);
  const [showEditInformation, setShowEditInformation] = useState(false);
  const [selectedCourseDay, setSelectedCourseDay] = useState({});
  const [courseDayInformation, setCourseDayInformation] = useState('');

  const classes = useStyles();

  const intl = useIntl();

  const history = useHistory();

  const params = useParams();
  if (isNaN(params.teamId)) history.goBack();

  /**
   * Notification
   */
  const [notification, setNotification] = React.useState({
    severity: 'info',
    show: false,
    message: '',
  });
  const notify = (message, color = 'info') => {
    setNotification({severity: color, message: message, show: true});
  }
  const closeNotification = () => setNotification({...notification, show: false});

  const client = new graphQLApi(useAuthDispatch(), props.history);
  const stableClient = useCallback(client, []);
  useEffect(() => {
    const id = params.teamId;
    setLoading(true);
    stableClient.query('{courseTeamDays' + (id ? '(filter:{course_team_id:'+id+'})' : '') +
    `{data{
      id
      start_at
      finish_at
      location
      address
      information
      course_day{
        sorting
        title
        description
      }
    }}` +
    '}').then(result => {
      setLoading(false);
      if (result && result.hasOwnProperty('courseTeamDays')) {
        const courseTeamDays = result.courseTeamDays.data;
        setTeamDays(courseTeamDays);
      }
  });
  }, [params]);

  const showEditInformationHandler = (courseDay) => {
    setShowEditInformation(true);
    setSelectedCourseDay(courseDay);
    setCourseDayInformation(courseDay.information);
  };

  const editInformationHandler = () => {
    setShowEditInformation(false);
    const variables = {
      id: "ID!",
      information: "String"
    };
    const data = {
      id: selectedCourseDay.id,
      information: courseDayInformation
    };
    stableClient.mutation('courseTeamDay', variables, data, 'id').then(result => {
      if (result && result.response) {
        notify(intl.formatMessage({id: "course_day.edit.saved", defaultMessage: "Course day was updated"}), "succes");
        const newTeamDays = [...teamDays];
        const index = teamDays.findIndex(d => d.id === selectedCourseDay.id);
        newTeamDays[index] = { ...newTeamDays[index], information: courseDayInformation };
        setTeamDays(newTeamDays);
      }
    });
  };

  const roles = authRoles();
  let isTrainer = false;
  if (roles.length) isTrainer = (roles.findIndex(r => r.priority <= 75) >= 0);

  return (
    <Card>
      <CardHeader title={intl.formatMessage({id: 'teams.team.course_program', defaultMessage: 'Course program'})} classes={{title: classes.header}} />
      <CardContent>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          message={notification.message}
          style={{backgroundColor: notification.severity}}
          open={notification.show}
          onClose={closeNotification}
          autoHideDuration={6000}
        />
        {teamDays.map(day => (
          <Accordion key={day.id}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls={day.id+"-content"}
              id={day.id+"-header"}
            >
              <Typography className={classes.heading}>Day {day.course_day.sorting}</Typography>
              <Typography className={classes.heading}>{moment(day.start_at).format("D. MMMM YYYY")}</Typography>
              <Typography className={classes.heading}>{moment(day.start_at).format("HH:mm")}</Typography>
              <Typography className={classes.heading}>-</Typography>
              <Typography className={classes.heading}>{moment(day.finish_at).format("HH:mm")}</Typography>
              <Typography className={classes.heading}>{day.course_day.title}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <Box display="flex" marginBottom={2}>
                <Typography variant="h4">Location:&nbsp;</Typography>
                <Typography>{day.location}</Typography>
              </Box>
              <Box display="flex" marginBottom={2}>
                <Typography variant="h4">Address:&nbsp;</Typography>
                <Typography>{day.address}</Typography>
              </Box>
              <Box marginBottom={2}>
                <Box display="flex" alignItems="center">
                  <Typography variant="h4">Information:&nbsp;</Typography>
                  <Button onClick={() => showEditInformationHandler(day)}>Edit</Button>
                </Box>
                <Typography>{day.information}</Typography>
              </Box>
              <Box>
                <Typography variant="h4">Description for the day:</Typography>
                <Typography>{day.course_day.description}</Typography>
              </Box>
            </AccordionDetails>
          </Accordion>
          ))}
      </CardContent>
      <Dialog open={showEditInformation} onClose={() => setShowEditInformation(false)}>
        <DialogTitle disableTypography><Typography variant="h3">Edit information for day {selectedCourseDay && selectedCourseDay.course_day && selectedCourseDay.course_day.sorting}</Typography></DialogTitle>
        <DialogContent>
          <TextField
            label="Information"
            InputLabelProps={{
              shrink: true
            }}
            variant="outlined"
            multiline
            fullWidth
            value={courseDayInformation}
            onChange={e => setCourseDayInformation(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowEditInformation(false)}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={editInformationHandler}>Save</Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}