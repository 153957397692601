import React from "react";
import {IntlProvider} from "react-intl";
import {BrowserRouter, Route, Switch, Redirect} from "react-router-dom";
import {ThemeProvider} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import theme from "assets/theme/theme";
import AdminLayout from "layouts/Admin";
import TeamsLayout from "layouts/Teams";
import AuthLayout from "layouts/Auth";
import {useAuthState, authRoles} from "contexts/Auth";
import {LocaleContext} from "contexts/Intl";
import locale_en from "translations/en.json";
import locale_da from "translations/da.json";

export default function App(props) {
    let { isAuthenticated } = useAuthState();
    const roles = authRoles();
    let [locale] = React.useContext(LocaleContext);
    const translations = {
        en: locale_en,
        da: locale_da,
    };
    let isAdmin = false;
    let isParticipant = false;
    if (isAuthenticated && roles.length) isAdmin = (roles.findIndex(r => r.priority <= 25) >= 0);
    if (isAuthenticated && roles.length) isParticipant = (roles.findIndex(r => r.priority <= 100) >= 0);

    return <IntlProvider
        locale={locale}
        defaultLocale="en"
        messages={translations[locale]}
    >
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <BrowserRouter>
                <Switch>
                    {isAuthenticated && isAdmin ? <Route path="/admin" render={(props) => <AdminLayout {...props} />}/> : ''}
                    {isAuthenticated && isParticipant ? <Route path="/teams" render={(props) => <TeamsLayout {...props} />}/> : ''}
                    {!isAuthenticated ? <Route path="/" render={(props) => <AuthLayout {...props} />}/> : ''}
                    <Redirect to={isAuthenticated && isAdmin ? "/admin" : isAuthenticated ? "/teams" : "/login"}/>
                </Switch>
            </BrowserRouter>
        </ThemeProvider>
    </IntlProvider>;
}