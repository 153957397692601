import React from "react";
import { useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";

// @material-ui/core components
import { Paper, Box, Typography, Link, ButtonBase, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { Description } from "@material-ui/icons";

import Logo from "assets/img/brand/logo.png";

import { useTeamsState } from "contexts/Teams";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "39px"
  },
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: 300,
    width: '100%',
  },
  image: {
    position: 'relative',
    height: 200,
    [theme.breakpoints.down('xs')]: {
      width: '100% !important', // Overrides inline-style
      height: 100,
    },
    '&:hover, &$focusVisible': {
      zIndex: 1,
      '& $imageBackdrop': {
        opacity: 0.15,
      },
      '& $imageMarked': {
        opacity: 0,
      },
      '& $imageTitle': {
        border: '4px solid currentColor',
      },
    },
    margin: 10,
  },
  focusVisible: {},
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create('opacity'),
  },
  imageTitle: {
    position: 'relative',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
}));

export default function Team(props) {
  const classes = useStyles();

  const intl = useIntl();

  const selectedTeam = useTeamsState().team;
  const loading = useTeamsState().loading;

  const courseCards = [
    {
      url: '/images/star-moments.jpg',
      title: intl.formatMessage({id: "teams.team.star_moments", defaultMessage: "Star moments"}),
      link: 'star-moments'
    },
    {
      url: '/images/focus-points.jpg',
      title: intl.formatMessage({id: "teams.team.focus_points", defaultMessage: "Focus points"}),
      link: 'focus-points'
    },
    {
      url: '/images/materials.jpg',
      title: intl.formatMessage({id: "teams.team.theory/materials", defaultMessage: "Theory / Materials"}),
      link: 'materials'
    },
    {
      url: '/images/activities.jpg',
      title: intl.formatMessage({id: "teams.team.implementation_activities", defaultMessage: "Implementation activities"}),
      link: 'activities'
    },
    {
      url: '/images/video.jpg',
      title: intl.formatMessage({id: "teams.team.video", defaultMessage: "Video"}),
      link: 'video'
    },
    {
      url: '/images/logbook.jpg',
      title: intl.formatMessage({id: "teams.team.logbook", defaultMessage: "Logbook"}),
      link: 'logbook'
    },
    {
      url: '/images/evaluation.jpg',
      title: intl.formatMessage({id: "teams.team.evaluation", defaultMessage: "Evaluation"}),
      link: 'evaluation'
    },
    {
      url: '/images/dashboard.jpg',
      title: intl.formatMessage({id: "teams.team.dashboard", defaultMessage: "Dashboard"}),
      link: 'dashboard'
    },
    {
      url: '/images/media.jpg',
      title: intl.formatMessage({id: "teams.team.media", defaultMessage: "Media"}),
      link: 'media'
    },
    {
      url: '/images/ideas.jpg',
      title: intl.formatMessage({id: "teams.team.concepts", defaultMessage: "Concepts"}),
      link: 'ideas'
    },
  ];

  if (selectedTeam.course && !selectedTeam.course.has_focus_points) delete courseCards[1];
  if (selectedTeam.course && !selectedTeam.course.has_implementation_activities) delete courseCards[3];
  if (selectedTeam.course && !selectedTeam.course.has_video_and_analysis) delete courseCards[4];

  const history = useHistory();

  const params = useParams();
  if (isNaN(params.teamId)) history.goBack();
  
  if (!selectedTeam.id && loading) return <CircularProgress />;
  
  return (
    <Paper>
      <Box classes={{ root: classes.root }}>
        <Box textAlign="center" marginBottom={4}>
          <img width={100} src={Logo} alt="" />
        </Box>
        <Typography variant="h2" color="primary">{intl.formatMessage({id: "teams.team.welcome.title"})} {selectedTeam.course.title}</Typography>
        <Typography dangerouslySetInnerHTML={{__html: selectedTeam.course.description}} />
        <Box display="flex" flexWrap="wrap">
          {selectedTeam.course.materials.filter(m => m.type === "theme" || m.type === "guide").map((material, index) => (
            <Box key={index} margin={2} display="flex" alignContent="center">
              <Description />
              <Link href={material.file_uri} target="_blank" rel="noreferrer" color="inherit">{material.title}</Link>
            </Box>
          ))}
        </Box>
        <div className={classes.wrapper}>
          {courseCards.map(courseCard => (
            <ButtonBase
              key={courseCard.title}
              focusRipple
              className={classes.image}
              focusVisibleClassName={classes.focusVisible}
              style={{
                width: 300
              }}
              onClick={() => history.push('/teams/' + params.teamId + '/' + courseCard.link)}
            >
              <span
                className={classes.imageSrc}
                style={{
                  backgroundImage: `url(${courseCard.url})`,
                }}
              />
              <span className={classes.imageBackdrop} />
              <span className={classes.imageButton}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  color="inherit"
                  className={classes.imageTitle}
                >
                  {courseCard.title}
                  <span className={classes.imageMarked} />
                </Typography>
              </span>
            </ButtonBase>
          ))}
        </div>
      </Box>
    </Paper>
  );
}