import React from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Hidden from "@material-ui/core/Hidden";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
// @material-ui/icons components
import ExitToApp from "@material-ui/icons/ExitToApp";
import SwapHoriz from "@material-ui/icons/SwapHoriz";
import Person from "@material-ui/icons/Person";

// core components
import componentStyles from "assets/theme/components/navbar-dropdown.js";
import {authSignOut, authUser, useAuthDispatch, authRoles} from "contexts/Auth";

const useStyles = makeStyles(componentStyles);

export default function NavbarDropdown(props) {
  const dispatch = useAuthDispatch();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const history = useHistory();
  const location = useLocation();
  const intl = useIntl();

  const roles = authRoles();
  let isAdmin = false;
  if (roles.length) isAdmin = (roles.findIndex(r => r.priority <= 25) >= 0);

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfile = () => {
    handleMenuClose();
    props.history.push('/admin/user-profile');
  };

  const handleLogOut = () => {
    handleMenuClose();
    authSignOut(dispatch);
  };

  const handleRoleSwitch = () => {
    if (location.pathname.search(/^\/admin/) >= 0) history.push("/teams");
    if (location.pathname.search(/^\/teams/) >= 0) history.push("/admin");
    handleMenuClose();
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Typography
        variant="h6"
        component="h6"
        classes={{ root: classes.menuTitle }}
      >{authUser().name}</Typography>
      {isAdmin && (
        <>
          <Box
            display="flex!important"
            alignItems="center!important"
            component={MenuItem}
            onClick={handleRoleSwitch}
          >
            <Box
              component={SwapHoriz}
              width="1.25rem!important"
              height="1.25rem!important"
              marginRight="1rem"
            />
            <span>
              {location.pathname.search(/^\/admin/) >= 0 ?
                intl.formatMessage({id: 'navbar.dropdown.change_to_user', defaultMessage: 'Change to user'}) :
                intl.formatMessage({id: 'navbar.dropdown.change_to_admin', defaultMessage: 'Change to admin'})
              }
            </span>
          </Box>
          <Divider component="div" classes={{ root: classes.dividerRoot }} />
        </>
      )}
      <Box
        display="flex!important"
        alignItems="center!important"
        component={MenuItem}
        onClick={handleProfile}
      >
        <Box
          component={Person}
          width="1.25rem!important"
          height="1.25rem!important"
          marginRight="1rem"
        />
        <span>{intl.formatMessage({id: 'navbar.dropdown.my_profile', defaultMessage: 'My profile'})}</span>
      </Box>
      {/*<Box
        display="flex!important"
        alignItems="center!important"
        component={MenuItem}
        onClick={handleMenuClose}
      >
        <Box
          component={Settings}
          width="1.25rem!important"
          height="1.25rem!important"
          marginRight="1rem"
        />
        <span>Settings</span>
      </Box>
      <Box
        display="flex!important"
        alignItems="center!important"
        component={MenuItem}
        onClick={handleMenuClose}
      >
        <Box
          component={EventNote}
          width="1.25rem!important"
          height="1.25rem!important"
          marginRight="1rem"
        />
        <span>Activity</span>
      </Box>
      <Box
        display="flex!important"
        alignItems="center!important"
        component={MenuItem}
        onClick={handleMenuClose}
      >
        <Box
          component={LiveHelp}
          width="1.25rem!important"
          height="1.25rem!important"
          marginRight="1rem"
        />
        <span>Support</span>
      </Box>*/}
      <Divider component="div" classes={{ root: classes.dividerRoot }} />
      <Box
        display="flex!important"
        alignItems="center!important"
        component={MenuItem}
        onClick={() => authSignOut(dispatch)}
      >
        <Box
          component={ExitToApp}
          width="1.25rem!important"
          height="1.25rem!important"
          marginRight="1rem"
        />
        <span>{intl.formatMessage({id: 'navbar.dropdown.logout', defaultMessage: 'Logout'})}</span>
      </Box>
    </Menu>
  );

  return (
    <>
      <Button
        edge="end"
        aria-label="account of current user"
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
        color="inherit"
        classes={{
          label: classes.buttonLabel,
          root: classes.buttonRoot,
        }}
      >
        <Avatar
          alt={authUser().name}
          classes={{
            root: classes.avatarRoot,
          }}
        >{authUser().name.toUpperCase().split(" ").map(w => w.substring(0, 1)).join("")}</Avatar>
        <Hidden smDown>{authUser().name}</Hidden>
      </Button>
      {renderMenu}
    </>
  );
}
